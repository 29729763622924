/* !
 * jQuery index v1.0.0
 * Copyright 2019, Connecty Inc.
 */
(function($, FUNCTIONS){
	const a = 'is-active';
	const arrowPrev = '<button type="button" class="slick-prev"><svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow_left" /></svg></button>';
	const arrowNext = '<button type="button" class="slick-next"><svg class="icon"><use xlink:href="/common/svg/sprite.svg#arrow_right" /></svg></button>';

	if($('.index-slide01').length) {
		$('.index-slide01').slick({
			infinite: true,
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 6000,
			slidesToShow: 1,
			arrows: false,
			dots: true,
			pauseOnFocus: false,
			pauseOnHover: false,
			pauseOnDotsHover: false,
		});
	}
	if($('.index-slide02').length) {
		$('.index-slide02').slick({
			infinite: true,
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 4000,
			slidesToShow: 3,
			arrows: false,
			dots: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: false,
					}
				}
			]
		});
	}
	if($('.index-slide03').length) {
		$('.index-slide03').slick({
			infinite: true,
			speed: 1000,
			autoplay: true,
			autoplaySpeed: 4000,
			slidesToShow: 4,
			arrows: true,
			prevArrow: arrowPrev,
			nextArrow: arrowNext,
			dots: false,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						centerPadding: '20%',
					}
				}
			]
		});
	}

	if($('.index-tabChange-content').length) {
		$('.index-tabChange-content').eq(0).addClass(a);
		$('.index-tabChange-btn').eq(0).addClass(a);

		$('.index-tabChange-btn').each(function(){
			$(this).on('click', function(){
				var index = $('.index-tabChange-btn').index(this);
				$('.index-tabChange-btn').removeClass(a);
				$(this).addClass(a);
				$('.index-tabChange-content').removeClass(a);
				$('.index-tabChange-content').eq(index).addClass(a);
			});
		});
	}

})(window.jQuery, window.FUNCTIONS);
